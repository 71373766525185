import styled from 'styled-components';
import styles from './css-modules/homeProjects.module.css';
import Heading from './general/heading';
import { useRef } from 'react';
import ReactIntersectionObserver from './layout/intersectionObserver';
import gsap from 'gsap';
import Parallax from './layout/parralax';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 60%;
    color: white;
    margin: 20vh 0px;

    @media screen and (max-width: 850px) {
        width: 75%;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    video {
        border-radius: 18px;
        width: 100%;
        filter: grayscale(1);
        transition: 0.6s ease;
        transform: translateY(-35px);
        opacity: 0;
    }

    p {
        margin: 0px;
        font-family: 'Inter', sans-serif;
        font-size: 0.98em;
        letter-spacing: -0.5px;
        opacity: 0;
        transition: 0.4s ease;
        width: 92%;
        align-self: normal !important;

        @media screen and (min-width: 1750px) {
            font-size: 1.35em;
        }

        @media screen and (max-width: 1024px) {
            font-size: 0.88em;
        }
    }

    .tasks {
        color: var(--yellow);
        font-size: 0.9em;
        margin-top: 15px;
        transform: translateY(5px);
        transition: 0.6s ease;

        @media screen and (min-width: 1750px) {
            font-size: 1.25em;
        }
        @media screen and (max-width: 1024px) {
            font-size: 0.8em;
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transform: scale(0);
        background-color: var(--yellow);
        transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);

        @media screen and (max-width: 1024px) {
            width: 15px;
            height: 15px;
            bottom: 15px;
        }
    }

    &:hover {
        p {
            opacity: 1;
        }

        .tasks {
            transform: translateY(0px);
        }

        video {
            filter: grayscale(0);
        }

        &:before {
            transform: scale(1);
        }
    }
`

function HomeProject({
    title, vid, mp4, url, desc, index, tasks
}) {

    const video = useRef();
    const para = useRef();

    const videoIn = () => {
        gsap.to(video.current, { opacity: 1, y: 0, duration: 0.5 });
    }
    
    const paraIn = () => {
        gsap.to(para.current, { opacity: 0.45, y: 0 });
    }

    return (
        <Container key={index + 'container'} onClick={() => window.open(url)}>
            <Parallax
                from={{y: 20}}
                to={{y: -20}}
                settings={{ start: "top center", end: `+=${400*index}`, scrub: 4 }}
            >
                <ReactIntersectionObserver callback={videoIn}>
                    <video ref={video} autoPlay loop muted>
                        <source src={vid} type="video/webm" />
                        <source src={mp4} type="video/mp4" />
                    </video>
                </ReactIntersectionObserver>
                <div className={styles.heading_container}>
                    <Heading
                        heading={title}
                        level={2} 
                        classname={`${styles.heading}`}
                        from = {{ opacity: 0, y: '25%' }} 
                        to = {{ opacity: 1, y: 0 }}
                        delay={0.1}
                        charDelay={0.02}
                        duration={0.8}
                    />
                </div>
                <ReactIntersectionObserver callback={paraIn}>                
                    <p ref={para}>{desc}</p>
                </ReactIntersectionObserver>
                <p className='tasks'>{tasks}</p>
            </Parallax>
        </Container>
    )
};

export default HomeProject;
