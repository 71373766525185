import styled from 'styled-components';
import Heading from './general/heading';
import styles from './css-modules/landing.module.css';
import Navbar from './navbar';
import Parallax from './layout/parralax';

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;

    background-color: #FBE095;
    padding: 0px var(--paddingSide);

    height: 70vh;
    width: 100%;

    @media screen and (min-width: 1750px) {
        height: 75vh;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`;

const Bottom = styled.div`
    width: 100vw;
    max-width: ${window.innerWidth}px;
    box-sizing: border-box;
    background-color: #100D0E;
    height: 7vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-left: calc(var(--paddingSide) * -1);
`;

export default function PageTitle({title = 'Project Archive'}) {
    return (
        <Container>
            <Navbar/>
            <Content>
                <Parallax
                    from={{y: 0}}
                    to={{y: -50}}
                    settings={{ start: "top center", end: `+=${800}`, scrub: 2 }}
                >
                    <Heading 
                        heading={title} 
                        level={1} 
                        classname={styles.mainTitle}
                        from = {{ opacity: 0, y: '-25%' }} 
                        to = {{ opacity: 1, y: 0 }}
                        delay={0.1}
                        charDelay={0.02}
                    />
                </Parallax>
            </Content>
            <Bottom/>
        </Container>
    );
}