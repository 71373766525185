import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AppContext } from '../contexts';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiMenuAlt3 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import Resume from '../resources/Hasan Khalid - Resume.pdf';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px 0px;
    font-size: 1.5em;
    color: ${props => props.$menu ? '#fff' : '#000'};
    z-index: ${props => props.$menu ? 6 : 3}
`

const Options = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    gap: 30px;

    button {
        background: none;
        padding: 0px;
        width: fit-content;
        cursor: pointer;
        padding: 5px 8px;
        border: none;
        transition: 0.6s ease;
        position: relative;

        p {
            margin: 0px;
            font-size: 1.1em;
            font-size: 'Inter', sans-serif;
            font-weight: 600;

            @media screen and (min-width: 1750px) {
                font-size: 1.7em;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;            
            background-color: black;
            transform: scaleY(0);
            transform-origin: bottom;
            mix-blend-mode: difference;
            border-radius: 5px;
            transition: 0.6s ease;
        }

        &:hover {
            color: var(--yellow);

            &:before {
                transform: scaleY(1);
            }
        }


        @media screen and (max-width: 768px) {
            display: none;
        }

    }

    
`;

const Mobile = styled.div`
    position: relative;
    display: none;

    @media screen and (max-width: 768px) {
            display: block;
    }
`;

const Logo = styled.img.attrs(props => ({
    src: props.$logo,
}))`
    width: 55px;
    cursor: pointer;
    margin-left: -5px;

    @media screen and (min-width: 1750px) {
        width: 70px;
    }
`;

function processCallback(callback, navigate) {
    if (typeof(callback) === 'string') {
        navigate(callback);
    }
    else {
        callback();
    }
}

function Navbar({
    options
}) {


    const navigate = useNavigate();
    const { LogoB, LogoW, menuOpen, setMenuOpen } = useContext(AppContext);

    return (
        <Container $menu={menuOpen}>
            <Logo $menu={menuOpen} $logo={LogoB} $invertLogo={LogoW} onClick={() => navigate('/')}/>
            <Options>
                {options && options.length > 0 && options.map((option, index) => {
                    return (
                        <button onClick={() => processCallback(option.callback, navigate)} key={`navItem${index}`}>
                            <p>{option.title}</p>
                        </button>
                    )
                })}
            </Options>
            <Mobile>
                <HiMenuAlt3 onClick={() => setMenuOpen(true)} style={{cursor: 'pointer', display: menuOpen ? 'none' : 'block'}}/>
                <MdClose onClick={() => setMenuOpen(false)} style={{cursor: 'pointer', color: 'black', display: !menuOpen ? 'none' : 'block'}}/>
            </Mobile>
        </Container>
    )
};

Navbar.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object)
}

Navbar.defaultProps = {
    options: [{
        title: 'Projects',
        callback: '/projects'
    },{
        title: 'Music',
        callback: () => window.open('https://open.spotify.com/artist/25EwXpRGFy61qsVC13PAp1?si=-mG6BMfZT9eFhrMvzuJbUw')
    },{
        title: 'Resume',
        callback: () => window.open(Resume)
    }]
}

export default Navbar;
