import { ViewContainer } from '../components/styled-containers';
import Landing from '../components/landing';
import styled from 'styled-components';
import Heading from '../components/general/heading';
import styles from './css-modules/views.module.css';
import HomeProject from '../components/homeProject';
import { homeWebsites } from '../data';
import Image from '../resources/img.jpg';
import Footer from '../components/footer';
import gsap from 'gsap';
import ReactIntersectionObserver from '../components/layout/intersectionObserver';
import { useRef, useEffect } from 'react';
import Marquee from '../components/general/marquee';
import { useNavigate } from 'react-router-dom';
import { BsEnvelopeFill, BsInstagram, BsGithub, BsLinkedin } from 'react-icons/bs';
import ReactGA from "react-ga4";

export default function Home () {

    const aboutPara = useRef();
    const collab = useRef();
    const image = useRef();
    const icons = useRef();

    const navigate = useNavigate();

    function CreateFadeAnimation(ref, duration = 0.5, delay = 0) {
        return function animation() {
            gsap.to(ref.current, { opacity: 1, y: 0, duration: duration, delay: delay });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
    }, []);

    return (
        <ViewContainer>
            <Landing/>
            <ProjectSection>
                <Heading 
                    heading='Projects' 
                    level={1}
                    classname={`${styles.heading} ${styles.white}`}
                    from = {{ opacity: 0, y: '25%' }} 
                    to = {{ opacity: 1, y: 0 }}
                    delay={0.1}
                    charDelay={0.02}
                    duration={0.8}
                />
                
                <Projects>
                    {homeWebsites.length > 0 && homeWebsites.map((website, index) => {
                        return (
                                <HomeProject
                                    title={website.title}
                                    vid={website.vid}
                                    mp4={website.mp4}
                                    url={website.url}
                                    desc={website.desc}
                                    tasks={website.tasks}
                                    {...index}
                                />
                        )
                    })}
                </Projects>

                <button onClick={() => navigate('/projects')} className={`${styles.page_button} ${styles.invert_button}`}>
                    <span>View All Projects</span>
                </button>

            </ProjectSection>

            <AboutSection>
                <AboutContent>
                    <div className={'content'}>
                        <Heading 
                            heading='About Me' 
                            level={2} 
                            classname={`${styles.heading} ${styles.black}`}
                            from = {{ opacity: 0, y: '25%' }} 
                            to = {{ opacity: 1, y: 0 }}
                            delay={0.1}
                            charDelay={0.02}
                            duration={0.8}
                        />
                        <ReactIntersectionObserver callback={CreateFadeAnimation(aboutPara , 0.6, 0.3)}>
                            <p ref={aboutPara}>
                                I am a Javascript developer, creative coder, designer and electronic music producer currently based in Washington DC. I am a recent MFA Design & Technology graduate from Parsons School of Design in New York City and am currently a contract developer for a Google.org project being carried out by Techsoup Global Network. Previously I was working at Plotree Info Design, an independent design studio I co-founded which specialized in interactive storytelling on the web.<br/>
                                <br/>
                                My creative practice in music, web and digital media is to engage with narratives in a way that creates new forms of storytelling. I am interested in exploring different mediums and forms to create emotive stories about the world we live in. I bring this creative approach to technology as well, challenging myself to approach problems in new ways that can lead us to creative solutions to complex problems. 
                            </p>
                        </ReactIntersectionObserver>
                        <Marquee/>
                    </div>
                    <div className='image'>
                        <ReactIntersectionObserver callback={CreateFadeAnimation(image , 0.6, 0.2)}>
                            <img ref={image} src={Image} alt="Hasan Khalid's portrait on a black background" className={styles.image}/>
                        </ReactIntersectionObserver>
                    </div>
                </AboutContent>

                <Separator/>

                <ReactIntersectionObserver callback={CreateFadeAnimation(collab , 0.6, 0)}>
                    <p className='collab' ref={collab}>Let's collaborate and create something beautiful together.</p>
                </ReactIntersectionObserver>
                <Heading 
                    heading='Get in touch with me' 
                    level={2} 
                    classname={`${styles.heading} ${styles.black}`}
                    from = {{ opacity: 0, y: '25%' }} 
                    to = {{ opacity: 1, y: 0 }}
                    delay={0.1}
                    charDelay={0.02}
                    duration={0.8}
                />

                <ReactIntersectionObserver callback={CreateFadeAnimation(icons , 0.8, 0.2)}>
                    <Icons ref={icons}>
                        <BsEnvelopeFill onClick={() => window.open('mailto:hasankhalid@plotree.studio')}/>
                        <BsGithub onClick={() => window.open('https://github.com/hasankhalid')}/>
                        <BsLinkedin onClick={() => window.open('https://www.linkedin.com/in/hasankhalid94/')}/>
                        <BsInstagram onClick={() => window.open('https://www.instagram.com/hopemakesmusic/')}/>
                    </Icons>
                </ReactIntersectionObserver>
            </AboutSection>

            <Footer/>
        </ViewContainer>
    )
};

const ProjectSection = styled.div`
    position: relative;
    background-color: var(--black);
    padding: 50px var(--paddingSide);
`;

const Projects = styled.div`
    display: flex;
    flex-direction: column;

    :nth-child(odd) {
        align-self: flex-end;
    } 
`;

const AboutSection = styled.div`
    position: relative;
    background-color: var(--yellow);
    padding: calc(100px + 7vh) var(--paddingSide) 100px var(--paddingSide);

    p {
        opacity: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 7vh;
        background-color: var(--black);
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .collab {
        font-size: 1.05em;
        line-height: 120%;
        letter-spacing: -0.6px;

        @media screen and (min-width: 1750px) {
            font-size: 1.35em;
            line-height: 200%;
        }

        @media screen and (max-width: 1024px) {
                font-size: 0.9em;
        }
    }
`

const AboutContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15vw;

    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .content {
        width: 75%;

        p {
            font-size: 0.98em;
            letter-spacing: -0.5px;
            margin-top: 45px;

            @media screen and (min-width: 1750px) {
                font-size: 1.25em;
            }

            @media screen and (max-width: 1300px) {
                font-size: 0.9em;
            }
            @media screen and (max-width: 1024px) {
                font-size: 0.8em;
            }
        }

        @media screen and (max-width: 750px) {
            width: 85%;
        }

    }



    .image {
        width: 25%;

        @media screen and (max-width: 750px) {
            width: 30%;
        }

        @media screen and (max-width: 500px) {
            width: 52%;
        }

        img {
            width: 100%;
            border-radius: 15px;
            transform: translateY(15px);
            opacity: 0;
        }
    }

`;

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--black);
    margin: 75px 0px;
`;

const Icons = styled.div`
    display: flex;
    gap: 20px;
    font-size: 1.1em;
    margin-top: 20px;
    transform: translateY(25px);
    opacity: 0;

    svg {
        cursor: pointer;
    }

    @media screen and (min-width: 1750px) {
        font-size: 1.4em;
        margin-top: 40px;
    }
`;




