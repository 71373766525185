import styled from 'styled-components';

const Container = styled.div`
    overflow: hidden;
    font-family: "PP-Editorial", sans-serif;
    font-weight: 600;
    font-size: 1.75em;
    margin-top: -15px;
    text-transform: uppercase;
    max-width: 55vw;

    @media screen and (min-width: 1750px) {
        font-size: 2em;
    }
    @media screen and (max-width: 1300px) {
        font-size: 1.6em;
    }
    @media screen and (max-width: 750px) {
        max-width: 90vw;
    }
`;

const Inner = styled.div`
    display: flex;
`;

const Line = styled.p`
    flex-shrink: 0;
    margin: 0;
    padding: 5px 10px;
    min-width: 100%;
    white-space: nowrap;
    animation-name: marqueeLine;
    animation-duration: 6s;
    animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);;
    animation-iteration-count: infinite;
    opacity: 1 !important;
    background-color: var(--black);
    color: var(--yellow);

    @keyframes marqueeLine {
        from {
            transform: translateX(0);
        }
        
        to {
            transform: translateX(-100%);
        }
    }
`



export default function Marquee () {
    return (
        <Container>
            <Inner>
                <Line>Web Design, Creative Coding, Generative Art, Data Visualization, Music Production & vibes.</Line>
                <Line>Web Design, Creative Coding, Generative Art, Data Visualization, Music Production & vibes.</Line>
            </Inner>
        </Container>
    );
}