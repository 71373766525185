import './App.css';

//Router Imports Below
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//Views Below
import Home from './views/home';
import Projects from './views/projects';

//Component imports below


//General Imports Below
import { useState, useEffect } from 'react';
import { AppContext } from './contexts';
import LogoB from './resources/logos/LogoB.png';
import LogoW from './resources/logos/LogoW.png';
import Menu from './components/menu';
import ReactGA from "react-ga4";

import Resume from './resources/Hasan Khalid - Resume.pdf';


function App() {
  const [menuOpen, setMenuOpen] = useState();

  useEffect(() => {
    ReactGA.initialize("G-9ZEEQYBWY2");
}, []);

  return (
    <BrowserRouter>
      <AppContext.Provider value={{LogoB, LogoW, menuOpen, setMenuOpen}}>
          <Menu 
            fill='var(--yellow)' 
            list={[{
                title: 'Projects',
                callback: '/projects'
            },{
                title: 'Music',
                callback: () => window.open('https://open.spotify.com/artist/25EwXpRGFy61qsVC13PAp1?si=-mG6BMfZT9eFhrMvzuJbUw')
            },{
                title: 'Resume',
                callback: () => window.open(Resume)
            }]} 
            transition={menuOpen}
          />
          <Routes>
              <Route 
                exact 
                path='/'
                element={
                    <Home/>
                } 
              />
              <Route 
                exact 
                path='/projects'
                element={
                    <Projects/>
                } 
              />
          </Routes>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
