import foodVideo from './resources/videos/tsgn_google.webm';
import lums from './resources/videos/lums.webm';
import worldsapart from './resources/videos/worldsapart.webm';
import port from './resources/videos/port.webm';
import waf from './resources/videos/waf.webm';

import foodVideo_mp4 from './resources/videos/tsgn_google.mp4';
import elections from './resources/videos/elections.mp4';
import lums_mp4 from './resources/videos/lums.mp4';
import worldsapart_mp4 from './resources/videos/worldsapart.mp4';
import port_mp4 from './resources/videos/port.mp4';
import waf_mp4 from './resources/videos/waf.mp4';



import beleaf from './resources/images/beleaf.png';
import compform from './resources/images/compform.gif';
import connecthumanity from './resources/images/connecthumanity.gif';
import hungermexico from './resources/images/hungermexico.gif';
import origins from './resources/images/origins.gif';
import panda from './resources/images/panda.gif';
import pep from './resources/images/pep.gif';
import dgi from './resources/images/dgi.gif';
import dro from './resources/images/dro.gif';
import web_elections from './resources/images/web_elections.jpg';
import web_caps from './resources/images/web_caps.jpg';
import web_capi from './resources/images/web_capi.jpg';
import hdx from './resources/images/hdx.gif';
import pcsw from './resources/images/pcsw.gif';
import terror from './resources/images/terror.gif';
import mall from './resources/images/mall.gif';


export const homeWebsites = [
    {
        title: 'Pakistan General Elections',
        vid: elections,
        mp4: elections, 
        desc: <span>An interactive map visualizing results of Pakistan General Elections from 1970-2024 - updated live on election day.</span>,
        url: 'https://elections.plotree.fun/',
        tasks: '✓ Web Design ✓ Web Development ✓ React ✓ D3 ✓ GSAP'
    },
    {
        title: 'Food, Farming & Climate Change',
        vid: foodVideo,
        mp4: foodVideo_mp4, 
        desc: <span>Collaborated with Techsoup Global Network and Google.org to design and develop a data story on the impact of climate change on food and farming in The United States.</span>,
        url: 'https://foodsecurity.datacommons.tsgn.org/',
        tasks: '✓ Web Design ✓ Web Development ✓ React ✓ D3 ✓ GSAP'
    },
    {
        title: 'Worlds Apart',
        vid: worldsapart,
        mp4: worldsapart_mp4, 
        desc: <span>Designed and developed an interactive website for Mai Kim Le's autobiographical book World's Apart which showcases her escape from wartorn Vietnam to the shores of the United States of America</span>,
        url: 'https://maikimle.com/',
        tasks: '✓ Web Design ✓ Web Development ✓ React ✓ D3 ✓ GSAP'
    },
    {
        title: 'Songs in Conversation',
        vid: port,
        mp4: port_mp4, 
        desc: <span>باتوں میں گیت (Songs in conversation) is an extended play music record that explores our shared stories and human connection through sampled conversations and poetry. I approach composition as a medium to reflect and respond to recordings of poetry and my conversations with people on the streets of New York City.</span>,
        url: 'https://parsons.edu/dt-2023/songs-in-conversation/',
        tasks: '✓ Music Production ✓ Media Arts ✓ Digital Storytelling ✓ Ableton ✓ Touchdesigner'
    },
    {
        title: 'Walking in the City',
        vid: lums,
        mp4: lums_mp4, 
        desc: <span>Collaborated with the Lahore University of Management Sciences to Design and develop a series of interactive websites based on student-led research projects on my hometown Lahore's history.</span>,
        url: 'https://archive.lums.edu.pk/interactives/home',
        tasks: '✓ Web Design ✓ Web Development ✓ React ✓ GSAP'
    },{
        title: "Women's Action Forum",
        vid: waf,
        mp4: waf_mp4, 
        desc: <span>Under the military rule of General Zia-ul-Haq (1977-1988), a movement was launched to resist the imposition of regressive laws against women. The Women's Action Forum (WAF) was a crucial part of the movement.</span>,
        url: 'https://archive.lums.edu.pk/interactives/womens-action-forum',
        tasks: '✓ Web Design ✓ Web Development ✓ React ✓ GSAP'
    }
];

export const archive = [
    {
        title: 'Hunger & Gender in Mexico',
        img: hungermexico,
        url: 'https://cemefi.datacommons.tsgn.org/',
        client: 'Techsoup Global Network / Google.org',
        tasks: 'Web Design & Development'
    },
    {
        title: 'State of Digital Inequity',
        img: connecthumanity,
        url: 'https://datadashboard.connecthumanity.fund/',
        client: 'Techsoup / Connect Humanity',
        tasks: 'Web Design & Development'
    },
    {
        title: 'Doing Good Index 2022',
        img: dgi,
        url: 'https://doinggoodindex.caps.org/',
        client: 'Centre for Asian Philanthropy and Society',
        tasks: 'Web Design & Development'
    },
    {
        title: 'The Mall – history and culture, now and then',
        img: mall,
        url: 'https://archive.lums.edu.pk/interactives/mall-road/home',
        client: 'Lahore University of Management Sciences',
        tasks: 'Web Design & Development'
    },
    {
        title: 'CAPS Website',
        img: web_caps,
        url: 'https://caps.org/',
        client: 'Centre for Asian Philanthropy and Society',
        tasks: 'Web Design & Development'
    },
    {
        title: 'Play That Drop',
        img: dro,
        url: 'https://playthatdrop.web.app/',
        client: 'Parsons Project',
        tasks: 'Game Artwork, Design & Development'
    },
    {
        title: 'HDx Website',
        img: hdx,
        url: 'https://www.haystack-dx.com/',
        client: 'Haystack Diagnostics',
        tasks: 'Web Design & Development'
    },
    {
        title: 'CAPI Website',
        img: web_capi,
        url: 'https://capi.org.in/',
        client: 'Centre for Asian Philanthropy India',
        tasks: 'Web Design & Development'
    },
    {
        title: 'Pakistan General Elections 2018',
        img: web_elections,
        url: 'https://plotree.github.io/elections/',
        client: 'Opensource Data visualization',
        tasks: 'Web Design & Development'
    },
    {
        title: 'P5.js Experiments',
        img: compform,
        url: 'https://sketches2022spring.compform.net/users/uv5keg2KmbS4Bp9di',
        client: 'Parsons Projects',
        tasks: 'Computational Arts'
    },
    {
        title: 'Beleaf in Yourself',
        img: beleaf,
        url: 'https://royyang.itch.io/beleaf-in-yourself',
        client: 'Parsons Project',
        tasks: 'Game Development'
    },
    {
        title: 'The Origins',
        img: origins,
        url: 'https://zhuoyingwang.itch.io/assign-3-the-origins',
        client: 'Parsons Project',
        tasks: 'Game Development'
    },
    {
        title: 'Pep Talk',
        img: pep,
        url: 'https://hasankhalid.github.io/netart-0101/week_two/index.html',
        client: 'Parsons Project',
        tasks: 'Web Design & Development'
    },
    {
        title: 'CSS Art',
        img: panda,
        url: 'https://hasankhalid.github.io/netart-0101/week_four/index.html',
        client: 'Parsons Project',
        tasks: 'Web Design & Development'
    },{
        title: '18 Years of Terror',
        img: terror,
        url: 'https://www.youtube.com/watch?v=xjA10STwlCw',
        client: 'Plotree Data visualization',
        tasks: 'Web Design & Development'
    },{
        title: 'ESW Dashboard',
        img: pcsw,
        url: 'https://hasankhalid.github.io/pcswVisPortal/',
        client: 'Punjab Commission on the status of Women',
        tasks: 'Web Design & Development'
    },
];