import styled from 'styled-components';
import Heading from './general/heading';
import styles from './css-modules/landing.module.css';
import Navbar from './navbar';
import Parallax from './layout/parralax';
import { useEffect, useState } from 'react';

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;

    background-color: #FBE095;
    padding: 0px var(--paddingSide);

    height: ${window.innerHeight}px;
    min-height: 550px;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8vh 0px;

    @media screen and (max-width: 1024px) {
        align-items: flex-start;
    }

    @media screen and (max-width: 500px) {
        padding: 5vh 0px;
    }
`

const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8vh 0px;

    @media screen and (max-width: 500px) {
        padding: 3vh 0px;
    }
`

const Bottom = styled.div`
    width: 100vw;
    background-color: #100D0E;
    height: 7vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-left: calc(var(--paddingSide) * -1);
    max-width: ${window.innerWidth}px;
    box-sizing: border-box;
`;

export default function Landing() {

    const [elementsLoaded, setElementsLoaded] = useState(false);

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", (event) => {
            setElementsLoaded(true);
        });
    }, []);

    return (
        <Container>
            <Navbar/>
                <Content>
                    <Parallax
                        from={{y: 0}}
                        to={{y: -50}}
                        settings={{ start: "top center", end: `+=${800}`, scrub: 2 }}
                    >
                        <Right>
                            <Heading
                                loaded = {elementsLoaded} 
                                heading='I tell stories with code.' 
                                id={`${styles.main_heading}`} 
                                level={1} 
                                classname={styles.mainTitle}
                                from = {{ opacity: 0, y: '-25%' }} 
                                to = {{ opacity: 1, y: 0 }}
                                delay={0.1}
                                charDelay={0.02}
                            />
                        </Right>
                    </Parallax>

                    <Parallax
                        from={{y: 0}}
                        to={{y: -40}}
                        settings={{ start: "top center", end: `+=${800}`, scrub: 2.5 }}
                    >
                        <Left>
                            <Heading 
                                loaded = {elementsLoaded} 
                                delay={0.6} 
                                heading="Hi, I'm Hasan" 
                                level={1} 
                                classname={styles.nameTitle}
                                from = {{ opacity: 0, y: '30%' }} 
                                to = {{ opacity: 1, y: 0 }}
                            />
                            <Heading 
                                loaded = {elementsLoaded} 
                                delay={0.6} 
                                charDelay={0.01}
                                duration={1}
                                heading="I make websites, music and everything in between" 
                                level={1} 
                                classname={styles.subTitle}
                                from = {{ opacity: 0, y: '25%' }} 
                                to = {{ opacity: 1, y: 0 }}
                            />
                        </Left>
                    </Parallax>
                </Content>
            <Bottom/>
        </Container>
    );
}