import styled from 'styled-components'

const Container = styled.div`
    background-color: var(--black);
    padding: 50px var(--paddingSide);
    color: white;
    display: flex;
    justify-content: space-between;
    position: relative;

    p {
        font-size: 0.9em;
    }

    .develop {
        color: #bdbdbd;
    }

    .links {
        display: flex;
        gap: 15px;
        height: fit-content;

        p {
            position: relative;
            cursor: pointer;
            
            &:before {
                content: '';
                position: absolute;
                bottom: 0px;
                right: -5%;
                height: 100%;
                width: 110%;
                mix-blend-mode: difference;
                transition: 0.8s ease;
                transform: scaleY(0);
                transform-origin: bottom;
                border-radius: 2px;
                background-color: var(--yellow);
            }

            &:hover {
                &:before {
                    transform: scaleY(1);
                }
            }
        }

        @media screen and (max-width: 310px) {
            flex-direction: column;
            gap: 4px;
        }
    }

    .plotree {
        cursor: pointer;
        font-family: 'PP-Editorial-Italic', serif;

        @media screen and (min-width: 1750px) {
            font-size: 1.25em;
            margin-top: 30px;
        }
    }

    .handcoded {
        font-size: 0.85em;
        color: #e0e0e0;
        position: absolute;
        bottom: 10px;
        
    }

    @media screen and (min-width: 1750px) {
        padding: 60px var(--paddingSide);

        p {
            font-size: 1.25em;
        }
    }

    @media screen and (max-width: 1024px) {
        p {
            font-size: 0.8em;
        }
    }

    @media screen and (max-width: 550px) {
        flex-direction: column;
    }

    
`;

export default function Footer() {
    return (
        <Container>
            <div>
                <p className='develop'>© {new Date().getFullYear()} - Designed & Developed by Hasan Khalid with love</p>
                <p className='plotree' onClick={() => window.open('https://plotree.studio/')}>I was a founder at Plotree Info Design</p>
            </div>

            <div className='links'>
                <p onClick={() => window.open('mailto:hasankhalid@plotree.studio')}>Email</p>
                <p onClick={() => window.open('https://github.com/hasankhalid')}>Github</p>
                <p onClick={() => window.open('https://www.linkedin.com/in/hasankhalid94/')}>LinkedIn</p>
                <p onClick={() => window.open('https://www.instagram.com/hopemakesmusic/')}>Instagram</p>
            </div>
        </Container>
    )
}