import styled from 'styled-components';
import ReactIntersectionObserver from './layout/intersectionObserver';
import { useRef } from 'react';
import gsap, { Power1 } from 'gsap';


const getMod = () => {
    if (window.innerWidth < 1025 && window.innerWidth > 800) {
        return 3;
    }
    else if (window.innerWidth < 801 && window.innerWidth > 500) {
        return 2;
    }
    if (window.innerWidth < 501) {
        return 0;
    }
    else {
        return 4;
    }
}

export default function ArchiveProject ({
    title,
    img,
    url,
    client,
    tasks,
    index
}) {

    const element = useRef();

    const mod = getMod();

    const fadeIn = () => {
        gsap.to(element.current, { opacity: 1, duration: 0.75, ease: Power1.easeInOut, delay: index % mod * 0.1 });
    }

    return (
        <ReactIntersectionObserver unsetFitContent callback={fadeIn} options={{ threshold: 0.7 }}>
            <Container ref={element} key={index + 'archiveP'} onClick={() => window.open(url)}>
                <Image $src={img}/>
                <h2>{title}</h2>
                <p className='tasks'>{tasks}</p>
                <p className='client'>{client}</p>
            </Container>
        </ReactIntersectionObserver>
    )
};

const Container = styled.div`
    position: relative;
    cursor: pointer;
    color: white;
    opacity: 0;

    img {
        filter: grayscale(1);
        transition: 0.5s ease;
    }

    &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        bottom: 5px;
        right: 0px;
        background-color: var(--yellow);
        transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
        transform: scale(0);
    }

    h2 {
        font-family: 'PP-Editorial', serif;
        font-weight: 400;
        font-size: 1.25em;
        margin: 15px 0px;

        @media screen and (min-width: 1750px) {
            font-size: 1.8em;
        }
    }

    .tasks {
        font-size: 0.8em;
        margin: 5px 0px;

        @media screen and (min-width: 1750px) {
            font-size: 1.2em;
        }
    }

    .client {
        font-size: 0.8em;
        margin: 5px 0px;
        color: var(--yellow);
        opacity: 0;
        transform: translateY(10px);
        transition: 0.8s ease;

        @media screen and (min-width: 1750px) {
            font-size: 1.2em;
        }
    }

    &:hover {
        img {
            filter: grayscale(0);
        }

        .client {
            transform: translateY(0px);
            opacity: 1;
        }

        &:before {
            transform: scale(1);
        }
    }
`;

const Image = styled.img.attrs(props => ({
    src: props.$src,
}))`
    height: 175px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    border-radius: 10px;

    @media screen and (min-width: 1750px) {
        height: 240px;
    }
`;
