import { ViewContainer } from '../components/styled-containers';
import styled from 'styled-components';
import PageTitle from '../components/pageTitle';
import { archive } from '../data';
import ArchiveProject from '../components/archive';
import Footer from '../components/footer';
import { useEffect } from 'react';
import ReactGA from "react-ga4";

export default function Projects () {

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
    }, []);

    return (
        <ViewContainer>
            <PageTitle/>
            <Black>
                {archive.length > 0 && archive.map((proj, index) => {
                    return (
                        <div className='project'>
                            <ArchiveProject
                                title={proj.title}
                                url={proj.url}
                                img={proj.img}
                                tasks={proj.tasks}
                                client={proj.client}
                                index={index}
                            />
                        </div>   
                    )
                })}
            </Black>

            <Footer/>
        </ViewContainer>
    )
}

const Black = styled.div`
    background-color: var(--black);
    padding: 150px var(--paddingSide);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;
    grid-row-gap: 25vh;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }

`