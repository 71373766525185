import styled from "styled-components";
import Navbar from "./navbar";
import { gsap, Power2 } from "gsap";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { AppContext } from '../contexts';

const ChapterHeading = styled.h3`
  font-family: "PP-Editorial", serif;
  font-weight: 400;
  font-size: 1.7em;
  margin: 1em 0em;

  @media screen and (max-width: 500px) {
      font-size: 1.45em;
  }

  @media screen and (max-width: 425px) {
      font-size: 1.3em;
  }
`;

const ClickHandler = styled.div`
    position: relative;
    width: fit-content;
    cursor: pointer;
    transition: 0.3s ease;
    align-self: center;

    &:before {
        content: '';
        position: absolute;
        bottom: 10px;
        left:0px;
        display: flex;
        width: 100%;
        height: 5px;
        background-color: var(--black);
        mix-blend-mode: difference;
        z-index: -1;
        transform: scaleY(0);
        transition: 0.4s ease;
    }

    &:hover {
        &:before {
            transform: scaleY(1);
        }
    }
`

const MenuItemContainer = styled.div`
    transform: translateY(50px);
    filter: blur(5px);
    opacity: 0;
    display: flex;
    flex-direction: column;
`;

function ChapterTitle({ title }) {
  return <ChapterHeading>{title}</ChapterHeading>;
}

function MenuItem({ number, title, handler, index, transition }) {

  const listNode = useRef();
  const { setMenuOpen } = useContext(AppContext);

  useEffect(() => {
    let listSettings = transition
      ? { y: 0, opacity: 1, filter: 'blur(0px)', duration: 0.8, ease: Power2.easeInOut, delay: 0.2 * (index + 1) }
      : { y: 50, opacity: 0, filter: 'blur(5px)', duration: 0.7 }; 

    gsap.to(listNode.current, listSettings);

  }, [transition, index]);

  const navigate = useNavigate();

  const clickHandler = (prop) => {
      if (typeof(prop) === 'string') {
          navigate(prop);
          setMenuOpen(false);
      }
      else {
          handler();
      }
  }

  return (
    <MenuItemContainer ref={listNode}>
        <ClickHandler onClick={() => clickHandler(handler)}>
          <ChapterTitle {...{ title }} />
        </ClickHandler>
    </MenuItemContainer>
  );
}

function MenuList({ list, transition }) {
  return (
    <>
      {list.map((item, index) => {
        return (
          <MenuItem
            key={"menuItem" + index}
            index={index}
            transition={transition}
            number={index + 1}
            title={item.title}
            handler={item.callback}
          />
        );
      })}
    </>
  );
}

const MenuContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    transform: translateX(100vw);
    z-index: 8;
    padding: 0px var(--paddingSide);
    box-sizing: border-box;
    border: 8px solid var(--black);
    background: ${(props) => props.$fill};
    height: ${window.innerHeight}px;
    width: 100%;

    @media screen and (min-width: 769px) {
        display: none;
    }
`;

const ListContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function Menu({ list, fill, transition }) {
  const container = useRef();

  useEffect(() => {
    let settings = transition
      ? { x: 0, duration: 0.9, ease: Power2.easeInOut }
      : { x: '100vw', duration: 0.8 };

    gsap.to(container.current, settings);
  }, [transition]);

  return (
    <MenuContainer ref={container} $fill={fill}>
      <Navbar menu />
      <ListContainer>
        <MenuList {...{ list, transition }}/>
      </ListContainer>
    </MenuContainer>
  );
}

export default Menu;
