import styled from 'styled-components';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import ReactIntersectionObserver from '../layout/intersectionObserver';
import PropTypes from 'prop-types';


const StyledHeading = styled.h1`
    position: relative;
    margin: 0px;
    overflow: hidden;

    .word {
        white-space: nowrap;
    }

    .word::after {
        content: ' ';
        white-space: normal;
    }
`

const Span = styled.span`
    display: inline-block;
    opacity: 0;
`

export default function Heading({ 
    heading, 
    level, 
    id = '',
    classname = '', 
    charDelay = 0.015, 
    delay = 0,
    from = { opacity: 0, y: '50%'}, 
    to = {opacity: 1, y: 0}, 
    duration = 0.8 
}) {
    const [chars, setChars] = useState([]);
    const element = useRef();

    useEffect(() => {
        let words = heading.split(" ");
        const splitchars = [];
        
        words.forEach((word) => {
            splitchars.push(word.split(""));
        });
       // console.log(splitchars);
       // const splitChars = heading.split("");

        setChars(splitchars);

    }, [heading]);

    const createOneTimeAnim = () => {
        let animated = false; 
        let multiplier = 0;

        const triggerAnimation = () => {
            const el = element.current;
            const children = el.children;

            if(children?.length === 0) {
                setTimeout(()=>{
                    triggerAnimation()
                },100)
                return;
            }

            if (children.length > 0 && !animated) {
                for (let i = 0; i < children.length; i++) {
                    const words = children[i];
                    const letters = words.children;
                    for (let j = 0; j < letters.length; j++) {
                        const child = letters[j];
                        multiplier++;
                        gsap.fromTo(child, { ...from }, { ...to, delay: (multiplier)*charDelay + delay, duration: duration});
                    }
                }
                animated = true;
            }
        }

        return triggerAnimation;
    }

    const myAnimation = createOneTimeAnim();

    return (
        <ReactIntersectionObserver callback={myAnimation}>
            <StyledHeading ref={element} aria-level={level} id={id} className={classname}>
                {chars.length > 0 && chars.map((char) => {
                    return (
                        <span className='word'>
                            {char.length > 0 && char.map((ch) => {
                                return (
                                    <Span>{ch}</Span>
                                )
                            })}
                        </span>
                    )
                })}
            </StyledHeading>
        </ReactIntersectionObserver>
    );
}